// Portuguese
export default{
    form: {
        startSurveyButton: "Vamos começar",
        nextButton: "Próximo",
        submitAnswerButton: "Enviar Resposta",
        continueButton: "Continuar",
        finishButton: "Concluir",
        headerPercentageCompletion: 'concluído',
        headerQuestionsCompleted: 'perguntas',
        headerDefaultErrorMessage: 'Ops. Algo deu errado.',
        brandingPoweredBy: 'Desenvolvido por',
        brandingPromoWhySurvey: 'Por que fazer uma pesquisa quando você pode usar <b>{product}</b>?',
        brandingPromoGetStarted: 'Comece agora!',
        submissionPending: "Enviando seu voiceform. Por favor, não feche a janela do navegador.",
        submissionCanCloseWindow: "Seu voiceform foi enviado com sucesso. Agora você pode fechar a janela 👍",
        mediaAttachmentOverlayCloseButton: "Fechar",
        expiredFormHeader: "Obrigado por visitar!",
        expiredFormMessage: "Parece que esta pesquisa expirou. Entre em contato com o criador do voiceform para informá-lo.",
        notPublishedFormHeader: "O voiceform não foi publicado!",
        notPublishedFormMessage: "Parece que esta pesquisa ainda não foi publicada. Entre em contato com o criador do voiceform para informá-lo.",
        notPublishedFormRetryButton: "Tentar Novamente",
        errorFormHeader: "Ops!",
        errorFormMessage: "Algo pode ter dado errado. Por favor, tente novamente mais tarde.",
        errorFormRetryButton: "Tentar Novamente",
        emptyFormHeader: "Obrigado por visitar!",
        emptyFormMessage: "Ops! Parece que este formulário está vazio no momento, sem perguntas ou campos para preencher. Pedimos desculpas pelo inconveniente. Para resolver este problema, entre em contato com o criador do formulário e informe-o sobre o problema. Agradecemos sua compreensão e cooperação!",

        restoreFormHeader: "Parece que você tem um formulário em andamento!",
        restoreFormMessage: "Gostaria de continuar de onde parou?",
        restoreFormButton: "Continuar Formulário",
        restoreFormButtonNew: "Recomeçar",

        audioPermissionHeader: "Gostaria de responder com a voz?",
        audioPermissionMessage: "Falar tornará a resposta rápida e fácil.",
        audioPermissionAcceptButton: "Sim, quero",
        audioPermissionDenyButton: "Não, obrigado",
        audioPermissionErrorHeader: "Microfone não disponível",
        audioPermissionErrorMessage: "Verifique as permissões do navegador para habilitar o acesso ao microfone.",
        audioPermissionErrorButton: "Ok",
        audioRecorderFailedUpload: "Falha ao enviar a gravação",
        silenceDetectorError: "Não consigo ouvir você. Verifique as preferências do seu microfone. Dica: pode ser seu microfone externo.",
        audioRecorderButtonRecord: "Gravar",
        audioRecorderButtonRecordMore: "Gravar Mais",
        voiceResponseSelectionMessage: "Selecione como você gostaria de responder...",
        voiceResponseSelectionOrDivider: "ou",
        voiceResponseTextInputPlaceholder: "Digite a resposta",
        voiceResponseTextInputCharacterCounterMinMessage: "Por favor, insira pelo menos {min} caracteres",
        voiceResponseTextInputCharacterCounterCurrentNumberMessage: "{count} caracteres (mínimo de {min} caracteres)",
        voiceResponseTextInputCharacterValidationMessage: "{count} caracteres (mínimo de {min} caracteres)",

        // checkbox
        checkboxValidationTooFew: "Você deve selecionar pelo menos {min} opção(ões)",
        checkboxValidationTooMany: "Você deve selecionar no máximo {max} opção(ões)",
        checkboxNoneOfTheAboveOption: "Nenhuma das anteriores",
        checkboxInvalidAnswer: "Resposta inválida.",

        // datepicker
        datePickerPlaceholder: "Selecione a data",

        // dropdown
        // email
        emailLabel: "E-mail",
        emailInvalid: "E-mail inválido.",
        // file-upload
        fileUploadPluginNameCamera: "Câmera",
        fileUploadPluginNameCameraVideo: "Gravar vídeo",
        fileUploadPluginScreenCast: "Compartilhamento de tela",
        fileUploadVideoImportFilesDefault: 'Grave ou faça upload de um vídeo via:',
        fileUploadVideoImportFilesLocalFilesDisabled: 'Selecione uma opção de gravação de vídeo:',
        fileUploadVideoImportFilesNoCamera: 'Pressione um botão para gravar a tela em vídeo',
        fileUploadVideoImportFilesNoScreenCast: 'Pressione um botão para gravar um vídeo',
        fileUploadVideoImportFilesNoCameraAndScreenCast: 'Nenhuma opção disponível para gravar um vídeo',

        // matrix
        matrixValidationMessage: "A resposta é válida.",
        matrixRow: "Linha",
        matrixColumn: "Coluna",
        matrixRowRequired: "A linha {rowTitle} é obrigatória.",
        matrixRadioGroup: "Grupo de rádio",
        matrixCheckboxGroup: "Grupo de caixas de seleção",
        matrixGroupRequired: "Para a linha {rowTitle}. {type} {groupTitle} é obrigatório.",
        matrixColumnRequired: "Para a linha {rowTitle}. A coluna {columnTitle} é obrigatória.",
        matrixColumnInvalid: "Para a linha {rowTitle}. A coluna {columnTitle} é inválida.",
        matrixRequired: "Obrigatório.",
        matrixNumericMustBeNumber: "Deve ser um número.",
        matrixNumericMustBeGreaterThenMin: "Deve ser maior que {min}.",
        matrixNumericMustBeLessThenMax: "Deve ser menor que {max}.",
        matrixNumericMustBeInteger: "Deve ser um número inteiro.",
        matrixNumericInvalidNumber: "Número inválido.",

        // name
        nameLabel: "Nome",
        nameInvalid: "Especifique o nome e sobrenome.",

        // nps
        npsNotLikely: "Nada provável",
        npsExtremelyLikely: "Extremamente provável",

        // numeric input
        numericInputRequired: "Obrigatório.",
        numericInputMustBeNumber: "Deve ser um número.",
        numericInputMustBeGreaterThenMin: "Deve ser maior que {min}.",
        numericInputMustBeLessThenMax: "Deve ser menor que {max}.",
        numericInputMustBeInteger: "Deve ser um número inteiro.",
        numericInputInvalidNumber: "Número inválido.",
        numericInputPlaceholder: "Digite um número",

        // phone
        phoneInvalid: "Número de telefone inválido.",
        phoneCountrySelectorLabel: 'Código do país',
        phoneCountrySelectorError: 'Escolha o país',
        phoneNumberLabel: 'Número de telefone',
        phoneExample: 'Exemplo:',

        // boolean
        booleanYesLabel: "Sim",
        booleanNoLabel: "Não",

        //questionStep
        questionStepAudioQuestionLabel: "Pergunta de Áudio",

        // errors
        invalidPhoneNumber: "{phone} é um número de telefone inválido.",
        invalidEmail: "{email} é um endereço de e-mail inválido.",
        questionIsRequired: "A pergunta é obrigatória.",
        answerIsNotValid: "A resposta não é válida.",
        unfinishedProbingDialogError: "Por favor, termine o diálogo.",
        cannotResumePartialResponse: "Não é possível retomar a resposta parcial.",
        failedToSubmitForm: "Falha ao enviar o formulário. Verifique sua conexão com a internet e tente novamente.",

        //language picker
        searchLanguage: "Pesquisar idioma",
    }
}
